<template>
	<v-container>
		<v-row>
			<v-col lg="6" md="10" offset-md="1" offset-lg="3">
				<Loading :show="loading" label="3, 2, 1..." />
				<RedactionLesson v-if="session" :session="session" :formationProgress="formationProgress" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Loading from 'vue-full-loading';
import RedactionLesson from '@/components/redaction/RedactionLesson.vue';

export default {
	name: 'SessionDetail',
	components: { RedactionLesson, Loading },
	data() {
		return {
			loading: false,
			session: null,
		};
	},
	async mounted() {
		const { sessionId, formationId } = this.$route.params;
		this.loading = true;

		if (!sessionId && !sessionId) return;

		const session = await this.$store.dispatch('sessions/getSessionDetail', { sessionId });

		const formationProgress = await this.$store.dispatch('profile/formationProgress', { idFormation: formationId });
		if (formationProgress.error) return;
		this.formationProgress = formationProgress;
		this.session = session;
		this.loading = false;

		//mixpanel tracker
		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('session-start');
		}
	},
};
</script>
